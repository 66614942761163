
import { Injectable, signal } from '@angular/core';
export const IPurl = `https://ipinfo.io`;
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  loader = signal<boolean>(false);
  showToolBar = signal<boolean>(false);
  constructor() { }
  
  redirectToURL(url: string, newTab: string) {
    window.open(url, newTab);
  }
}
